import { FC } from "react";
import Footer from "../Components/Footer";

import searchseekLogo from "../img/fwibby-high-resolution-logo-transparent.png";

const containerStyle = {
  padding: "20px",
  borderRadius: "10px",
  margin: "20px",
  marginTop: "120px",
  fontFamily: "Arial, sans-serif"
};

const headingStyle = {
  fontSize: "28px",
  marginBottom: "10px",
  color: "#333"
};

const paragraphStyle = {
  fontSize: "16px",
  lineHeight: "1.6",
  color: "#555",
  width: "700px"
};

const linkStyle = {
  color: "#007bff",
  textDecoration: "none",
  fontWeight: "bold"
};

const PageContactUs: FC = () => {
  return (
    <>
      <div style={containerStyle}>
        <h1 style={headingStyle}>Connect with Us</h1>

        <div className="contact-page">
          <div className="contact-page-links">

          </div>
        </div>

        <p style={paragraphStyle}>
          If you have any questions or need assistance, please don't hesitate to reach out to us. We are committed to creating a supportive and responsive environment, always ready to assist our valued users. Our dedication to exceptional service defines our ethos, ensuring that your experience with us is marked by prompt, thorough, and personalized support. Your inquiries are not just tasks; they are opportunities for us to showcase our commitment to your satisfaction. Feel free to contact us whenever you need guidance or have questions – we're here to serve you with unwavering dedication and a customer-centric approach.
        </p>

        <h2 style={headingStyle}>About Our Company</h2>
        <p style={paragraphStyle}>
          <strong></strong> Searchseek LTD <a style={linkStyle} href="/about">Learn More</a><br />
          <strong>Location:</strong> Hagaaton 8, Oranit, 3702221
        </p>
        <p style={paragraphStyle}>
          We anticipate hearing from you soon!
        </p>
        <h2 style={headingStyle}>Contact Information</h2>
        <p style={paragraphStyle}>
          <strong>Email:</strong> <a style={linkStyle} href="mailto:contact@SearchSeek.com">contact@SearchSeek.com</a><br />
          <strong>Phone:</strong> 052-2252949<br />
          <strong>Website:</strong> <a style={linkStyle} href="https://www.SearchSeek.com/">https://www.SearchSeek.com/</a>
        </p>

        <br /><br /><br />

        <div style={{ textAlign: "center" }}>
          <a href="/">
            <img
              src={searchseekLogo}
              alt="Search Engine Illustration"
              style={{ maxWidth: '500px', marginBottom: '20px' }}
            />
          </a>

          <br /><br /><br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageContactUs;
