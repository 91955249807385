import React from "react";
import "../Header/Header.css";
import logo4Image from "../../img/fwibby-high-resolution-logo-transparent.png";
import SearchComponent from "../SearchHeader";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="header">
      <a href="/">
        <img src={logo4Image} alt="lexo" className="mlogo" />
      </a>
      <SearchComponent />
    </div>
  );
};
export default Header;
