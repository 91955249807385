import React, { useState, useEffect, useMemo } from "react";
import "../App.css";

interface SearchResultProps {
  results: {
    organic: any[];
    sponsored: any[];
    products: any[];
    related: any[];
  };
  setRelatedValues: any;
}

const SearchResult: React.FC<SearchResultProps> = ({
  results,
  setRelatedValues,
}) => {

  const [likedWebsites, setLikedWebsites] = useState<string[]>([]);
  const [counterMap, setCounterMap] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const cachedLikedWebsites = localStorage.getItem('likedWebsites');

    if (cachedLikedWebsites) {
      setLikedWebsites(JSON.parse(cachedLikedWebsites));
      console.log("setLikedWebsites(JSON.parse(cachedLikedWebsites));");
    }

    const cachedCounters = localStorage.getItem('searchCounters');

    if (cachedCounters) {
      setCounterMap(JSON.parse(cachedCounters));
    }


  }, []);

  const handleIncrementCounter = (siteHost: string) => {
    const updatedCounters = { ...counterMap, [siteHost]: (counterMap[siteHost] || 0) + 1 };
    setCounterMap(updatedCounters);
  };

  // const handleLike = (siteHost: string) => {
  //   console.log('like we got!' + siteHost)
  //   setLikedWebsites([...likedWebsites, siteHost]);
  // };

  useEffect(() => {
    localStorage.setItem('searchCounters', JSON.stringify(counterMap));
  }, [counterMap]);

  useEffect(() => {
    localStorage.setItem('likedWebsites', JSON.stringify(likedWebsites));

  }, [likedWebsites]);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const cltrk = urlParams.get('cltrk');

  useEffect(() => {
    const delayedExecution = () => {
      console.log('ads');

      const h2Elements = document.querySelectorAll('h2.title');

      h2Elements.forEach((h2WithTitle) => {
        const parentDiv = h2WithTitle.closest('div');
        const generatedLink = h2WithTitle.querySelector('a');
        const checkbtn = parentDiv?.querySelector('button');
        if (checkbtn){
          checkbtn.style.display = 'none'
        }
        const spancheckbtn = parentDiv?.querySelector('span');
        if (spancheckbtn){
          spancheckbtn.style.display = 'none'
        }
        if (parentDiv && generatedLink) {
          console.log('ads');
          const counterButton = document.createElement("button");
          counterButton.textContent = "Counter";
          counterButton.classList.add("btn");
          counterButton.style.color = counterMap[generatedLink.innerText] ? 'gray' : 'black';

          counterButton.addEventListener("click", () => {
            handleIncrementCounter(generatedLink.innerText);
            counterButton.style.color = 'gray';
          });

          const counterDisplay = document.createElement("span");
          counterDisplay.textContent = `Count: ${counterMap[generatedLink.innerText] || 0}`;
          counterDisplay.classList.add("counter-display");

          const buttonContainer = document.createElement("div");
          buttonContainer.classList.add("gap-2");

          buttonContainer.appendChild(counterButton);
          buttonContainer.appendChild(counterDisplay);
          parentDiv.appendChild(buttonContainer);
        }
      });
    };

    const timeoutId = setTimeout(delayedExecution, 6000);

    return () => clearTimeout(timeoutId);
  });


  useEffect(() => {

    const bodyScript = document.createElement("script");
    bodyScript.innerHTML = `
    function myCallback(ads) {
      console.log(ads);
    }
    YOUTextlink({
        cid: '${urlParams.get('cid')}',
        sid: '${urlParams.get('sid')}',
        market: '${urlParams.get('market')}',
        lang: '${urlParams.get('lang')}',
        ads_n: 20,
        query_parameter: 'query',
        lazyloading: true,
        sub_id: '${urlParams.get('sub_id')}',
        disabled_extensions: {
            action: true,
            callout: true,
            favicon: true,
            fourth_line_annotation: true,
            image: true,
            merchant_rating: true,
            review: true,
            sitelinks: true,
            smart_annotation: true
        },
        cltrk: '${cltrk || ''}'
    });
  `;
    document.body.appendChild(bodyScript);
  }, [urlParams, cltrk]);
  return (
    <div className="div-mob">
      {<div data-youads="ads"></div>}
    </div>
  );
};

export default SearchResult;