import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Results.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";
import SearchResultsComponent from "../Components/SearchRes";

interface SearchResultData {
  organic: string[];
  sponsored: string[];
  products: string[];
  related: string[];
}

interface Props {}

export const Results: React.FC<Props> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";

  const [results] = useState<SearchResultData>({
    organic: [],
    sponsored: [],
    products: [],
    related: [],
  });
  const [query] = useState(queryParam);
  const [nextToken] = useState<string | null>(null);

  const handleSearch = async () => {
   

    const navEvent = new Event("navigation");
    window.dispatchEvent(navEvent);
  };

  const setRelatedValues = async (search: any) => {
    window.location.href = `/results?query=${search}`;
    handleSearch();
  };

  useEffect(() => {
    if (query !== undefined) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="results-container">
        <SearchResultsComponent
          results={results}
          setRelatedValues={setRelatedValues}
        />

        {nextToken && (
          <button
            className="more-results-button"
            onClick={() => {
              handleSearch();
            }}
          >
            More Results...
          </button>
        )}
      </div>
      <Footer />
    </>
  );
};
