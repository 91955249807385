import * as React from "react";
import Footer from "../Components/Footer";
import SearchComponent from "../Components/SearchHeader";

import logo4Image from "../img/fwibby-high-resolution-logo-transparent.png";

interface Props {}

export const Home: React.FC<Props> = () => {
  return (
    <>
      <div className="main-search-container">
        <a href="/">
          <img src={logo4Image} alt="fwibby" />
        </a>
        <SearchComponent />
      </div>
      <Footer />
    </>
  );
};
