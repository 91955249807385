import React from "react";
import "./Footer.css";
import logo4Image from "../img/fwibby-high-resolution-logo-white-transparent.png"
import logo6Image from "../img/2456073.png"
const Footer: React.FC = () => {
  return (
    <footer>
      <div >

        <div className="horizontal-scroll2"><div className="horizontal-scroll"></div></div>
        <a href="/">
          <img src={logo4Image} alt="fwibby search" style={{ width: '370px' }} />
        </a>
        <div className="footer-lexo-links">
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-of-use">Terms</a>
          <a href="https://lookupmedia.co/">- Lookupmedia</a>
        </div>
        <div style={{ textAlign: "center" }}>
          <img src={logo6Image} alt="fwibby search" style={{ width: '420px' }} />
        </div>

      </div>
    </footer>
  );
};

export default Footer;
