import { FC } from "react";
import Footer from "../Components/Footer";

import fwibbyLogo from "../img/fwibby-high-resolution-logo-transparent.png";

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "20px",
  marginTop: "120px",
};
const paragraphStyle = {
  fontSize: "16px",
  lineHeight: "1.6",
  color: "#555",
  width: "700px"
};

const linkStyle = {
  color: "#007bff",
  textDecoration: "none",
  fontWeight: "bold"
};
const PagePrivacyPolicy: FC = () => {
  return (
    <>
      <div style={containerStyle}>
        <div>
          <h2>Explore the Fwibby Search Experience</h2>
          <p style={{ width: "400px" }}>
            Welcome to Fwibby, where our search engine is designed to revolutionize your online exploration. We are dedicated to providing top-notch results curated from across the web.
          </p>

          <p style={{ width: "400px" }}>
            Engineered for speed and accuracy, the Fwibby search experience aims to simplify information retrieval. Whether you're looking for news, articles, or any other content, our search engine ensures efficient and enjoyable online searches.
          </p>

          <p style={{ width: "400px" }}>
            Our search algorithm is optimized for speed and reliability, delivering swift and precise results. With a comprehensive web index, our platform offers access to a wealth of information. The user-friendly interface ensures seamless navigation, accompanied by our unwavering commitment to privacy, ensuring a secure and confidential search experience.
          </p>
          <h3>Our product </h3>
          <p style={{ width: "400px" }}>delivers optimal and high-quality search results sourced from our managed internet network. Additionally, we've introduced a unique feature allowing users to mark results for future reference, enhancing personalization and accuracy in their search experience.</p>
          <br /><br /><br /> <p style={{ width: "400px" }}>
            Dive into the Fwibby search engine to unlock the full potential of your online exploration. We highly value your feedback and suggestions, so please don't hesitate to reach out to us.
          </p><br />
          <p style={paragraphStyle}>
            <strong></strong> Searchseek LTD <a style={linkStyle} href="/about">Learn More</a><br />
            <strong>Location:</strong> Hagaaton 8, Oranit, 3702221
          </p>
          <p style={paragraphStyle}>
            <strong>Email:</strong> <a style={linkStyle} href="mailto:contact@SearchSeek.com">contact@SearchSeek.com</a><br />
            <strong>Phone:</strong> 052-2252949<br />
            <strong>Website:</strong> <a style={linkStyle} href="https://www.SearchSeek.com/">https://www.SearchSeek.com/</a>
          </p>

          <br /><br />
          <a href="/">
            <img
              src={fwibbyLogo}
              alt="Fwibby Search Engine Logo"
              style={{ maxWidth: '500px', marginBottom: '20px' }}
            />
          </a><br /><br /><br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PagePrivacyPolicy;